<template>
  <v-card
    tile
    class="mx-auto"
    :loading="table_loading"
    :disabled="table_loading"
  >
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{ name: 'providers' }"
          />
          <span
            class="text-h6 ml-1"
            v-text="
              `${provider.name ? `${provider.name} | ` : ''}${
                $route.meta.title
              }`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                color="error"
                class="ml-1"
                :href="
                  url_documents +
                  '/migrations/formato_migracion_prestaciones.xlsx'
                "
                target="_blank"
              >
                <v-icon v-text="'mdi-download'" />
              </v-btn>
            </template>
            <span v-text="'Descargar formato'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="4" v-if="!file_load">
          <v-file-input
            v-model="data.document_file_0"
            counter
            truncate-length="15"
            label="Formato XLSX"
            accept=".xlsx"
            show-size
            dense
          />
        </v-col>
        <v-col cols="2" v-if="!file_load">
          <v-btn
            depressed
            color="success"
            :disabled="data.document_file_0 === null"
            @click="fileLoad"
          >
            <v-icon left v-text="'mdi-upload'" />
            Analizar
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="file_load">
          <v-btn depressed color="info" @click="fileReload">
            <v-icon left v-text="'mdi-reload'" />
            Cargar otro formato
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="file_load">
          <v-card flat :loading="table_loading" :disabled="table_loading">
            <v-card-title>
              <span
                v-text="
                  `DETALLE ${table_loading ? '' : `(${table_data.length})`}`
                "
              />
              <v-spacer />
              <v-text-field
                v-model="table_search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              />
            </v-card-title>
            <v-data-table
              :headers="table_headers"
              :items="table_data"
              :items-per-page="-1"
              :search="table_search"
              :loading="table_loading"
              :item-class="itemRowBackground"
              item-key="index"
              :expanded.sync="expanded"
              show-expand
              dense
            >
              <template v-slot:item.index="{ item }">
                <span class="font-weight-bold" v-text="item.index" />
              </template>
              <template v-slot:item.type="{ item }">
                <span
                  :class="
                    item.benefit_type ? '' : 'red--text font-weight-medium'
                  "
                  v-text="item.type"
                />
              </template>
              <template v-slot:item.description="{ item }">
                <span
                  :class="
                    item.benefit_detail_id
                      ? ''
                      : item.benefit
                      ? 'blue--text'
                      : ''
                  "
                  v-text="item.description"
                />
              </template>
              <template v-slot:item.amount="{ item }">
                <span
                  v-text="numberFormat(item.amount)"
                  :class="
                    item.percentage_difference &&
                    item.percentage_difference != 0
                      ? 'blue--text'
                      : ''
                  "
                />
                <span
                  v-if="
                    item.benefit_detail_id &&
                    item.percentage_difference &&
                    item.percentage_difference != 0 &&
                    Math.abs(parseFloat(item.amount)) !=
                      parseFloat(
                        item.benefit.benefit_details.find(
                          (v) => v.id === item.benefit_detail_id
                        ).amount
                      )
                  "
                  :class="
                    item.percentage_difference > 0 ? 'red--text' : 'green--text'
                  "
                >
                  <small
                    v-text="`(${item.percentage_difference.toFixed(2)}%)`"
                  />
                  <br />
                  <small
                    v-text="
                      numberFormat(
                        item.benefit.benefit_details.find(
                          (v) => v.id === item.benefit_detail_id
                        ).amount
                      )
                    "
                  />
                </span>
              </template>
              <template v-slot:item.frequency="{ item }">
                <span
                  :class="
                    item.benefit &&
                    item.benefit.benefit_details.find(
                      (v) => v.id === item.benefit_detail_id
                    ) &&
                    item.frequency !=
                      item.benefit.benefit_details.find(
                        (v) => v.id === item.benefit_detail_id
                      ).frequency
                      ? 'blue--text'
                      : ''
                  "
                  v-text="item.frequency"
                />
              </template>
              <template v-slot:item.bill="{ item }">
                <span
                  :class="
                    item.benefit &&
                    item.benefit.benefit_details.find(
                      (v) => v.id === item.benefit_detail_id
                    ) &&
                    item.bill !=
                      item.benefit.benefit_details.find(
                        (v) => v.id === item.benefit_detail_id
                      ).bill
                      ? 'blue--text'
                      : ''
                  "
                  v-text="item.bill"
                />
              </template>
              <template v-slot:item.definition="{ item }">
                <span
                  :class="
                    item.benefit &&
                    item.benefit.benefit_details.find(
                      (v) => v.id === item.benefit_detail_id
                    ) &&
                    item.definition !=
                      item.benefit.benefit_details.find(
                        (v) => v.id === item.benefit_detail_id
                      ).definition
                      ? 'blue--text'
                      : ''
                  "
                  v-text="item.definition"
                />
              </template>
              <template v-slot:item.action="{ item }">
                <v-tooltip v-if="!item.benefit_type" left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mr-1"
                      v-on="on"
                      fab
                      x-small
                      color="warning"
                      @click.prevent="createBenefitTypeDialog(item.type)"
                    >
                      <v-icon v-text="'mdi-file-upload'" />
                    </v-btn>
                  </template>
                  <span v-text="'Cargar tipo de prestación'" />
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td
                  v-if="item.benefit && item.benefit_type"
                  :colspan="headers.length"
                  class="pt-2"
                >
                  <div
                    v-text="
                      `${item.benefit_type.benefit_type}-${item.benefit.code}`
                    "
                    class="text-h6"
                  />
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th v-text="'#'" />
                          <th v-text="'Descripción'" />
                          <th v-text="'Precio'" />
                          <th v-text="'Frecuencia'" />
                          <th v-text="'Factura única'" />
                          <th v-text="'Definición'" />
                          <th v-text="'Acciones'" width="58px" />
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(benefit_detail, i) in item.benefit
                            .benefit_details"
                          :key="i"
                        >
                          <td v-text="i + 1" />
                          <td
                            v-text="benefit_detail.description"
                            :class="
                              benefit_detail.id == item.benefit_detail_id
                                ? 'green lighten-5'
                                : ''
                            "
                          />
                          <td
                            v-text="numberFormat(benefit_detail.amount)"
                            :class="
                              benefit_detail.id == item.benefit_detail_id
                                ? 'green lighten-5'
                                : ''
                            "
                          />
                          <td v-text="benefit_detail.frequency" />
                          <td v-text="benefit_detail.bill" />
                          <td v-text="benefit_detail.definition" />
                          <td>
                            <v-tooltip
                              v-if="
                                !item.benefit_detail_id &&
                                item.benefit &&
                                benefit_detail.description != item.description
                              "
                              left
                            >
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  class="mr-1"
                                  v-on="on"
                                  fab
                                  x-small
                                  color="secondary"
                                  @click.prevent="
                                    updateDescriptionDialog(
                                      item,
                                      benefit_detail
                                    )
                                  "
                                >
                                  <v-icon v-text="'mdi-comment-text'" />
                                </v-btn>
                              </template>
                              <span v-text="'Actualizar descripción'" />
                            </v-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <br />
                  <br />
                  <br />
                </td>
              </template>
            </v-data-table>
            <div class="text-right pt-5 pb-3">
              <v-btn
                dark
                color="warning"
                elevation="2"
                x-large
                @click.prevent="execMigration"
              >
                <v-icon v-text="'mdi-check'" left />
                Ejecutar migración
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- DIALOG CREATE BENEFIT TYPE start-->
    <v-dialog
      v-model="create_benefit_type_dialog"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card tile :disabled="table_loading" :loading="table_loading">
        <v-toolbar dark>
          <v-toolbar-title v-text="'Agregar tipo de prestación'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="create_benefit_type_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="create_benefit_type_dialog">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2 class="text-caption" v-text="'PRESTACIÓN'" />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" xs="12" md="12" class="text-center">
                        <span
                          class="text-caption font-weight-bold"
                          v-text="'Tipo'"
                        />
                        <br />
                        <v-icon
                          large
                          color="orange darken-2"
                          v-text="'mdi-chevron-down'"
                        />
                        <br />
                        {{ create_benefit_type.benefit_type }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-btn block color="warning" @click.prevent="createBenefitType">
                  <v-icon v-text="'mdi-file-upload'" left />
                  Aceptar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG CREATE BENEFIT TYPE end-->
    <!-- DIALOG UPDATE DESCRIPTION start-->
    <v-dialog
      v-model="update_description_dialog"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card tile :disabled="table_loading" :loading="table_loading">
        <v-toolbar dark>
          <v-toolbar-title v-text="'Actualizar descripción'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn dark text @click="update_description_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="update_description_dialog">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2 class="text-caption" v-text="'PRESTACIÓN'" />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" xs="12" md="4" class="text-center">
                        <ViewData
                          label="Tipo"
                          :value="update_description.type"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4" class="text-center">
                        <ViewData
                          label="Código"
                          :value="update_description.code"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4" class="text-center">
                        <ViewData
                          label="Precio"
                          :value="numberFormat(update_description.amount)"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="12" class="text-center">
                        <span
                          class="text-caption font-weight-bold"
                          v-text="'Descripción'"
                        />
                        <br />
                        {{ update_description.db_description }}
                        <br />
                        <v-icon
                          large
                          color="orange darken-2"
                          v-text="'mdi-chevron-down'"
                        />
                        <br />
                        {{ update_description.description }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  color="secondary"
                  @click.prevent="descriptionUpdate"
                >
                  <v-icon v-text="'mdi-comment-text'" left />
                  Aceptar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG UPDATE DESCRIPTION end-->
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
  URL_DOCUMENTS,
} from "../../control";
import { catalog } from "../../requests/catalogRequest";
import { show, storeUpdate } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      provider_id: parseInt(this.$route.params.provider_id),
      provider: {},
      login: this.$store.getters.getLogin,
      table_data: [],
      table_loading: false,
      table_search: "",
      table_headers: [
        {
          text: "#",
          value: "index",
        },
        {
          text: "Tipo",
          filterable: true,
          value: "type",
        },
        {
          text: "Código",
          filterable: true,
          value: "code",
        },
        {
          text: "Descripción",
          filterable: true,
          value: "description",
        },
        {
          text: "Precio",
          filterable: true,
          value: "amount",
        },
        {
          text: "Frecuencia",
          filterable: true,
          value: "frequency",
        },
        {
          text: "Factura única",
          filterable: true,
          value: "bill",
        },
        {
          text: "Definición",
          filterable: true,
          value: "definition",
        },
        { text: "Acciones", value: "action", sortable: false, width: "58px" },
        { text: "", value: "data-table-expand" },
      ],
      expanded: [],
      benefit_types: [],
      benefit_types_loading: true,
      rules: rules(),
      create_benefit_type: {},
      create_benefit_type_dialog: false,
      update_description: {},
      update_description_dialog: false,
      file_load: false,
      data: {},
      url_documents: URL_DOCUMENTS,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    defaultData() {
      this.data = {
        provider_id: this.provider_id,
        document_file_0: null,
        file_name: null,
      };
    },
    fileLoad() {
      this.file_load = false;
      this.tableData();
    },
    tableData() {
      this.table_loading = true;

      storeUpdate("providers/benefits", this.login.token, null, this.data, null)
        .then((response) => {
          //console.log(response);
          this.table_data = response.data.list_benefits;
          this.data.file_name = response.data.file_name;
          this.table_loading = false;
          this.file_load = true;
        })
        .catch((e) => {
          console.log(e);
          this.$swal.fire(msgAlert("error", "Formato de documento invalido"));
          this.table_loading = false;
        });
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    itemRowBackground: function (item) {
      return item.benefit ? "" : "red lighten-5";
    },
    benefitTypes() {
      catalog("benefit_types", this.login.token).then((response) => {
        this.benefit_types = response;
        this.benefit_types_loading = false;
      });
    },
    createBenefitTypeDialog(benefit_type) {
      this.table_loading = true;
      this.create_benefit_type = {
        id: null,
        benefit_type: benefit_type,
        created_by_id: this.login.id,
        updated_by_id: this.login.id,
      };

      this.table_loading = false;
      this.create_benefit_type_dialog = true;
    },
    createBenefitType() {
      this.$swal
        .fire(msgConfirm("¿Confirma agregar el tipo de prestación?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.table_loading = true;

            Axios.post(
              URL_API + "/benefit/types",
              this.create_benefit_type,
              headersToken(this.login.token)
            ).then((response) => {
              response = response.data;
              this.$swal.fire(
                msgAlert(
                  response.success ? "success" : "error",
                  response.message
                )
              );
              if (response.success) {
                this.benefitTypes();
                this.table_loading = false;
                this.create_benefit_type_dialog = false;
                this.tableData();
              } else {
                console.log(response.message);
              }
            });
          }
        });
    },
    updateDescriptionDialog(item, benefit_detail) {
      this.table_loading = true;
      this.update_description = {
        id: benefit_detail.id,
        description: item.description,
        updated_by_id: this.login.id,
        type: item.type,
        code: item.code,
        amount: benefit_detail.amount,
        db_description: benefit_detail.description,
      };

      this.table_loading = false;
      this.update_description_dialog = true;
    },
    descriptionUpdate() {
      this.$swal
        .fire(msgConfirm("¿Confirma actualizar la descripción?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.table_loading = true;
            Axios.post(
              URL_API + "/benefits/details/description",
              this.update_description,
              headersToken(this.login.token)
            ).then((response) => {
              response = response.data;
              this.$swal.fire(
                msgAlert(
                  response.success ? "success" : "error",
                  response.message
                )
              );
              if (response.success) {
                this.table_loading = false;
                this.update_description_dialog = false;
                this.tableData();
              } else {
                console.log(response.message);
              }
            });
          }
        });
    },
    fileReload() {
      this.$swal
        .fire(msgConfirm("¿Confirma seleccionar el formato nuevamente?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.file_load = false;
            this.defaultData();
            this.table_data = [];
          }
        });
    },
    execMigration() {
      this.$swal
        .fire(msgConfirm("¿Confirma ejecutar la migración?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.table_loading = true;

            Axios.post(
              URL_API + "/benefits/migration",
              {
                list_benefits: this.table_data,
                updated_by_id: this.login.id,
                provider_id: this.provider_id,
                file_name: this.data.file_name,
              },
              headersToken(this.login.token)
            ).then((response) => {
              //console.log(response);
              response = response.data;

              this.$swal.fire(
                msgAlert(
                  response.success ? "success" : "error",
                  response.message
                )
              );
              if (response.success) {
                this.$router.push({
                  name: "prestaciones",
                  params: { provider_id: this.provider_id },
                });
              } else {
                console.log(response.message);
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal.fire(
            msgAlert(
              "error",
              "Error en archivo de migración. Revise cuidadosamente el documento."
            )
          );
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    show("providers", this.login.token, this.provider_id).then((response) => {
      this.provider = response;
    });

    this.benefitTypes();

    this.defaultData();
  },
};
</script>